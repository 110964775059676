var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"org"},[_c('Search',{attrs:{"params":_vm.params,"paramsHeaders":_vm.paramsHeaders,"hasSearch":false}},[_c('template',{slot:"operate"},[_c('button',{directives:[{name:"permission",rawName:"v-permission",value:(['/hrms/org/add']),expression:"['/hrms/org/add']"}],staticClass:"btn submit-btn",staticStyle:{"margin-left":"auto"},on:{"click":function($event){return _vm.addOrgClick('')}}},[_c('i',{staticClass:"iconfont icon-tianjia"}),_c('span',[_vm._v("添加")])])])],2),_c('el-table',{staticClass:"mt15",staticStyle:{"width":"100%"},attrs:{"data":_vm.filterTableData,"row-key":"id","border":"","default-expand-all":"","maxHeight":"700"}},[_c('el-table-column',{attrs:{"type":"index","width":"50","label":"序号"}}),_c('el-table-column',{attrs:{"prop":"name","label":"人事组织名称","width":"500"}}),_c('el-table-column',{attrs:{"prop":"type","label":"类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.orgType[row.type])+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"courtName","label":"对应法院组织"}}),_c('el-table-column',{attrs:{"prop":"realName","label":"审批人员"}}),_c('el-table-column',{attrs:{"label":"操作","width":"400","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('button',{directives:[{name:"permission",rawName:"v-permission",value:(['/hrms/org/move-org']),expression:"['/hrms/org/move-org']"}],staticClass:"btn",class:{color1: row.orgIndex - 1 < row.maxIndex, color4: row.orgIndex === 1},attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.up(row)}}},[_vm._v("上移")]),_c('button',{directives:[{name:"permission",rawName:"v-permission",value:(['/hrms/org/move-org']),expression:"['/hrms/org/move-org']"}],staticClass:"btn",class:{color1: row.orgIndex > 0, color4: row.orgIndex === row.maxIndex},attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.down(row)}}},[_vm._v("下移")]),_c('button',{directives:[{name:"permission",rawName:"v-permission",value:(['/hrms/org/add']),expression:"['/hrms/org/add']"}],staticClass:"btn color1",attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.addOrgClick(row)}}},[_vm._v("新建下级")]),_c('button',{directives:[{name:"permission",rawName:"v-permission",value:(['/hrms/org/modify']),expression:"['/hrms/org/modify']"}],staticClass:"btn color2",attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.editOrg(row)}}},[_vm._v("编辑")]),_c('button',{directives:[{name:"permission",rawName:"v-permission",value:(['/hrms/org/delete']),expression:"['/hrms/org/delete']"}],staticClass:"btn color3",attrs:{"size":"small","type":"danger"},on:{"click":function($event){return _vm.delOrg(row)}}},[_vm._v("删除")])])}}])})],1),_c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.openAddOrg,"width":"500px"},on:{"update:visible":function($event){_vm.openAddOrg=$event}}},[_c('RowForm',{ref:"orgForm",attrs:{"headers":_vm.formHeaders,"form":_vm.form,"rules":_vm.rules},scopedSlots:_vm._u([{key:"courtId",fn:function(ref){
var header = ref.header;
return [_c('el-select',{attrs:{"size":"small","filterable":"","clearable":"","disabled":_vm.form.type === 'DEPT'},on:{"change":_vm.courtChange},model:{value:(_vm.form.courtId),callback:function ($$v) {_vm.$set(_vm.form, "courtId", $$v)},expression:"form.courtId"}},_vm._l((header.typeList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)]}},{key:"userId",fn:function(ref){
var header = ref.header;
return [_c('el-select',{attrs:{"value":_vm.form.userId && _vm.form.userId.split(','),"size":"small","clearable":"","disabled":_vm.form.type === 'DEPT',"multiple":""},on:{"input":function (vals) { return _vm.form.userId = vals.join(','); }}},_vm._l((header.typeList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)]}}])},[_c('template',{slot:"type"},[_c('el-select',{attrs:{"size":"small","clearable":""},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},_vm._l((_vm.orgTypeList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],2),_c('template',{slot:"footer"},[_c('button',{staticClass:"btn plain-btn",on:{"click":function($event){_vm.openAddOrg = false}}},[_vm._v("关闭")]),(_vm.form.id === '')?_c('button',{staticClass:"btn submit-btn",on:{"click":function($event){return _vm.submitOrg('continue')}}},[_vm._v("保存并继续添加")]):_vm._e(),_c('button',{staticClass:"btn submit-btn",on:{"click":_vm.submitOrg}},[_vm._v("保存")])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }