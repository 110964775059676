<template>
    <div class="org">
        <Search :params="params" :paramsHeaders="paramsHeaders" :hasSearch="false">
            <template slot="operate">
                <button style="margin-left:auto;" class="btn submit-btn" @click="addOrgClick('')" v-permission="['/hrms/org/add']">
                    <i class="iconfont icon-tianjia"></i>
                    <span>添加</span>
                </button>
            </template>
        </Search>

        <el-table class="mt15" :data="filterTableData" row-key="id" border default-expand-all style="width: 100%;" maxHeight="700" >
            <el-table-column type="index" width="50" label="序号"></el-table-column>
            <el-table-column prop="name" label="人事组织名称" width="500"></el-table-column>
            <el-table-column prop="type" label="类型">
                <template slot-scope="{ row }">
                    {{orgType[row.type]}}
                </template>
            </el-table-column>
            <el-table-column prop="courtName" label="对应法院组织"></el-table-column>
            <el-table-column prop="realName" label="审批人员"></el-table-column>
            <el-table-column label="操作" width="400" align="center">
                <div slot-scope="{ row }">
                    <button class="btn" :class="{color1: row.orgIndex - 1 < row.maxIndex, color4: row.orgIndex === 1}" size="small" type="primary" @click="up(row)" v-permission="['/hrms/org/move-org']">上移</button>
                    <button class="btn" :class="{color1: row.orgIndex > 0, color4: row.orgIndex === row.maxIndex}" size="small" type="primary" @click="down(row)" v-permission="['/hrms/org/move-org']">下移</button>
                    <button class="btn color1" size="small" type="primary" @click="addOrgClick(row)" v-permission="['/hrms/org/add']">新建下级</button>
                    <button class="btn color2" size="small" type="primary" @click="editOrg(row)" v-permission="['/hrms/org/modify']">编辑</button>
                    <button class="btn color3" size="small" type="danger" @click="delOrg(row)" v-permission="['/hrms/org/delete']">删除</button>
                </div>
            </el-table-column>
        </el-table>
        
        <el-dialog :title="title" :visible.sync="openAddOrg" width="500px" >
            <RowForm ref="orgForm" :headers="formHeaders" :form="form" :rules="rules">
                <template slot="type">
                    <el-select v-model="form.type" size="small" clearable>
                        <el-option v-for="item in orgTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </template>
                <template slot="courtId" slot-scope="{header}">
                    <el-select v-model="form.courtId" size="small" @change="courtChange" filterable clearable :disabled="form.type === 'DEPT'">
                        <el-option v-for="item in header.typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </template>
                <template slot="userId" slot-scope="{header}">
                    <el-select :value="form.userId && form.userId.split(',')" @input="vals => form.userId = vals.join(',')" size="small" clearable :disabled="form.type === 'DEPT'" multiple>
                        <el-option v-for="item in header.typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </template>
            </RowForm>
            <template slot="footer">
                <button class="btn plain-btn" @click="openAddOrg = false">关闭</button>
                <button class="btn submit-btn" @click="submitOrg('continue')" v-if="form.id === ''">保存并继续添加</button>
                <button class="btn submit-btn" @click="submitOrg">保存</button>
            </template>
        </el-dialog>

    </div>
</template>

<script>
import Search from '@/components/search';
import { getAllOrgList , examineUserList , getCourtList , createOrg , updateOrg , delOrg, moveOrg } from '@/api/hrAPI.js';
import { filterTree , unique, flat } from '@/utils/handle.js';

export default {
	name: 'org',
    components: {
        Search
    },
    props: {
        addPath: {
            type: String,
            default: '',
        },
		libName: {
			type: String,
			require: true
		}
    },
    data(){
        return {
            params: {
                type: '',
                name: ''
            },
            daterange: [],
            paramsHeaders: [
                {
                    label: '类型',
                    value: 'type',
                    type: 'select',
                    typeList: this.EnumList.orgTypeList,
                },
                {
                    label: '组织名称',
                    value: 'name'
                },
            ],
            tableData: [],
            openAddOrg: false,
            formHeaders: [
                {
                    label: '人事组织名称',
                    value: 'name',
                },
                {
                    label: '类型',
                    value: 'type',
                    slot: 'type'
                },
                {
                    label: '对应法院组织',
                    value: 'courtId',
                    slot: 'courtId',
                    typeList: []
                },
                {
                    label: '审批人员',
                    value: 'userId',
                    slot: 'userId',
                    typeList: []
                },
            ],
            form: {
                id: '',
                name: '',
                pid: '',
                type: '',
                courtId: '',
                userId: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入组织名称', trigger: 'change' },
                ],
                type: [
                    { required: true, message: '请选择组织类型', trigger: 'change' }
                ],
            },
            title: '',
			map: {},
        }
    },
    computed: {
        orgType(){
            return this.EnumType.orgType;
        },
        orgTypeList(){
            return this.EnumList.orgTypeList;
        },
        filterTableData(){
            return filterTree(this.tableData,item => {
                return item.name.includes(this.params.name) && (item.type === this.params.type || this.params.type === '');
            })
        }
    },
    created(){
        getCourtList({ type: "COURT" }).then(res => {
            this.formHeaders[2].typeList = res.map(item => {
                return {
                    label: item.name,
                    value: item.id
                }
            });
        })
        this.getTableData();
    },
    methods: {
        getTableData(){
            getAllOrgList({}).then(res => {
                this.tableData = res;
				flat(res, this.map)
            })
        },
        addOrgClick(row){
            this.$refs.orgForm && this.$refs.orgForm.resetForm();
            Object.keys(this.form).forEach(key => {
                this.form[key] = '';
            })
            if(row){
                this.form.pid = row.id;
            }else{
                this.form.pid = '0';
            }
            this.title = '添加组织结构';
            this.openAddOrg = true;
        },
        async editOrg(row){
            this.title = '修改组织结构';
            this.$refs.orgForm && this.$refs.orgForm.resetForm();
            Object.keys(this.form).forEach(key => {
                this.form[key] = row[key] || '';
            })
            await this.courtChange(this.form.courtId,row);
            this.form.userId = row.userId;          //  重新赋值，法院改变的时候会重置userId
            this.openAddOrg = true;
        },
        submitOrg(type){
            this.$refs.orgForm.submit().then(() => {
                if(this.form.id){
					const note = `${this.libName}编辑组织${this.form.name}`
					const path = '/hrms/org/modify'
		  			this.$store.commit('status/SET_LOG', {path, note})
                    updateOrg(this.form).then(res => {
                        this.$message.success('修改组织成功');
                        this.openAddOrg = false
                        this.getTableData();
                    })
                }else{
					const note = `${this.libName}新增组织${this.form.name}`
					const path = '/hrms/org/add'
		  			this.$store.commit('status/SET_LOG', {path, note})
                    createOrg(this.form).then(res => {
                        this.$message.success('新增组织成功');
                        this.getTableData();
                        if(type === 'continue'){
                            this.$refs.orgForm.resetForm();
                            Object.keys(this.form).forEach(key => {
                                if(key === 'pid'){
                                    return;
                                }
                                this.form[key] = '';
                            })
                        }else{
                            this.openAddOrg = false;
                        }
                    })
                }
            })
            
            
        },
        delOrg(row){
            this.$confirm('确认要删除该组织结构吗 ？删除后，该组织将无法恢复。', '确认删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
				const note = `${this.libName}删除组织${row.name}`
				const path = '/hrms/org/delete'
		  		this.$store.commit('status/SET_LOG', {path, note})
                delOrg({ id: row.id }).then(res => {
                    this.$message.success('删除成功');
                    this.getTableData();
                })
            }).catch(() => {
                
            });
        },
        courtChange(val,row){
            return new Promise((resolve,reject) => {
                examineUserList({ orgId: val },this.addPath).then(res => {      //  唯一一个不是已hrms开头但需要转化的接口
                    this.formHeaders[3].typeList = res.map(item => {
                        return {
                            label: item.realName,
                            value: item.id
                        }
                    });
                    if(row && row.userId){              //  强行组合已有的数据
                        const realNames = row.realName.split(',');
                        const ids = row.userId.split(',');
                        const list = ids.map((item,index) => {
                            return {
                                label: realNames[index],
                                value: item
                            }
                        })
                        this.formHeaders[3].typeList.push(...list);
                        unique(this.formHeaders[3].typeList,(a,b) => {
                            return a.value === b.value;
                        })
                    }
                    resolve();
                })

            })
        },
		up(row) {
			const { id, orgIndex } = row
			if(orgIndex > 1) {
				const path = '/hrms/org/move-org'
		  		const note = `${this.libName}上移${row.name}`
		  		this.$store.commit('status/SET_LOG', {path, note})
				moveOrg({id, orgIndex: orgIndex - 1})
				.then(() => {
					this.$message.success('上移成功');
                    this.getTableData();
				})
			}
		},
		down(row) {
			const { id, orgIndex, maxIndex } = row
			if(orgIndex < maxIndex) {
				const path = '/hrms/org/move-org'
		  		const note = `${this.libName}下移${row.name}`
		  		this.$store.commit('status/SET_LOG', {path, note})
				moveOrg({id, orgIndex: orgIndex + 1})
				.then(() => {
					this.$message.success('下移成功');
                    this.getTableData();
				})
			}
		}
    }, 
}
</script>

<style lang="less" scoped>
.org{
}
.pagination{
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
}
.color1{
    color: #31B5DB;
    border-color: #31B5DB;
    background-color: #E5FAFF;
}
.color2{
    color: #1990FD;
    border-color: #7AB8FB;
    background-color: #EBF5FF;
}
.color3{
    color: #FF4242;
    border-color: #FF7878;
    background-color: #FDF0F0;
}
.color4{
    color: #606060;
    border-color: #C9C9C9;
    background-color: #EDF1F2;
}
</style>